import {Outlet} from 'react-router-dom';
import HostGuard from './Host/HostGuard';
import IntegrationGuard from './Integrations/IntegrationGuard';
import WidgetFrame from './Widget/WidgetFrame';

/** Layout component that wraps every page that is part of the widget. */
function Layout() {
  return (
    <WidgetFrame>
      <IntegrationGuard>
        <HostGuard>
          <Outlet />
        </HostGuard>
      </IntegrationGuard>
    </WidgetFrame>
  );
}

export default Layout;
