import {type ConnectionInfoData} from '@pinecone-experience/messages';
import {useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Layout from './Layout';
import ConnectButton from './Widget/ConnectButton';
import Finish from './Widget/Finish';

/** App is the root component of the widget. */
function App() {
  const [connectionInfo, setConnectionInfo] = useState<ConnectionInfoData | null>(null);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:integrationId" element={<Layout />}>
          <Route path="" element={<ConnectButton setConnectionInfo={setConnectionInfo} />} />
          <Route path="finish" element={<Finish connectionInfo={connectionInfo} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
