import {createContext, useContext} from 'react';

type HostContextType = {
  /** The HTTP origin of the page hosting the widget. */
  origin: string;
};

export const HostContext = createContext<HostContextType | null>(null);

/** Gets information about the page that is hosting the widget. */
export const useHost = () => {
  const ctx = useContext(HostContext);
  if (!ctx) throw Error('useHost must be used within a HostGuard');
  return ctx;
};
