import {Typography} from '@mui/material';
import {isAllowedOrigin} from '@pinecone-experience/integrations';
import {MessageAction} from '@pinecone-experience/messages';
import {useBroadcast, useListenOnce} from '@pinecone-experience/messages/hooks';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {useCallback, useMemo, useState} from 'react';
import {useIntegrationInfo} from '../Integrations/integrationInfo';
import {HostContext} from './hostContext';

type HostGuardProps = {
  children: React.ReactNode;
};

/**
 * HostProvider manages the information about the page that is hosting the widget. In particular, it
 * tracks the origin of the host.
 */
function HostGuard({children}: HostGuardProps) {
  const integration = useIntegrationInfo();

  const [origin, setOrigin] = useState<string | null>(null);

  const onReceiveAck = useCallback(
    (_data: undefined, _source: MessageEventSource, sourceOrigin: string) => {
      setOrigin(sourceOrigin);
    },
    [],
  );
  useListenOnce(MessageAction.ACK, () => true, onReceiveAck);
  useBroadcast(MessageAction.READY, window.parent);

  const isAllowed = useMemo(
    () => origin && isAllowedOrigin(integration, origin),
    [integration, origin],
  );
  const ctxValue = useMemo(() => (origin ? {origin} : null), [origin]);

  return (
    <LoadingContainer loading={!origin}>
      {isAllowed ? (
        <HostContext.Provider value={ctxValue}>{children}</HostContext.Provider>
      ) : (
        <Typography color="error">Error: Origin {origin} is not allowed.</Typography>
      )}
    </LoadingContainer>
  );
}

export default HostGuard;
