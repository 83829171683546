import {type Integration} from '@pinecone-experience/integrations';
import {createContext, useContext} from 'react';

export const IntegrationInfoContext = createContext<Integration | null>(null);

/** Gets information about the integration being connected. */
export const useIntegrationInfo = () => {
  const ctx = useContext(IntegrationInfoContext);
  if (!ctx) throw Error('useIntegrationInfo must be used within an IntegrationGuard');
  return ctx;
};
