import {useEffect} from 'react';
import {
  broadcastMessage,
  listenForMessage,
  listenForMessageOnce,
  respondToMessage,
  respondToMessageOnce,
  sendMessage,
} from './core';
import type {MessageAction, MessageCallback, MessageData, MessageResponder} from './types';

export const useSend = <T extends MessageAction>(
  action: T,
  targetWindow: Window,
  targetOrigin: string | null,
  data?: MessageData<T>,
) => {
  useEffect(
    () => sendMessage(action, targetWindow, targetOrigin, data),
    [action, data, targetOrigin, targetWindow],
  );
};

export const useBroadcast = <T extends MessageAction>(
  action: T,
  targetWindow: Window,
  data?: MessageData<T>,
) => useEffect(() => broadcastMessage(action, targetWindow, data), [action, data, targetWindow]);

export const useListen = <T extends MessageAction>(
  action: T,
  isAllowedOrigin: (origin: string) => boolean,
  callback: MessageCallback<T>,
) => {
  useEffect(
    () => listenForMessage(action, isAllowedOrigin, callback),
    [action, callback, isAllowedOrigin],
  );
};

export const useListenOnce = <T extends MessageAction>(
  action: T,
  isAllowedOrigin: (origin: string) => boolean,
  callback: MessageCallback<T>,
) => {
  useEffect(
    () => listenForMessageOnce(action, isAllowedOrigin, callback),
    [action, callback, isAllowedOrigin],
  );
};

export const useRespond = <T extends MessageAction, W extends MessageAction>(
  toAction: T,
  withAction: W,
  isAllowedOrigin: (origin: string) => boolean,
  withData?: MessageResponder<T, W>,
) => {
  useEffect(
    () => respondToMessage(toAction, withAction, isAllowedOrigin, withData),
    [isAllowedOrigin, toAction, withAction, withData],
  );
};

export const useRespondOnce = <T extends MessageAction, W extends MessageAction>(
  toAction: T,
  withAction: W,
  isAllowedOrigin: (origin: string) => boolean,
  withData?: MessageResponder<T, W>,
) => {
  useEffect(
    () => respondToMessageOnce(toAction, withAction, isAllowedOrigin, withData),
    [isAllowedOrigin, toAction, withAction, withData],
  );
};
